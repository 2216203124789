import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Premier electricians in the Baltimore area"
        keywords={[`residential electrician`, `electrician`, `commercial electrician`, `smart home installation`]}
      />
    </Layout>
  );
}

export default IndexPage;
